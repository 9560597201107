import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../components/App';
// import { headData } from '../mock/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

export default () => {
  // const { title, lang, description } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ernesto Celi</title>
        <html lang="en" />
        <meta name="description" content="Welcome to my website 🙂" />
        <meta property="og:description" content="Welcome to my website 🙂" />
        <meta property="og:url" content="https://ernestoceli.com" />
        <meta property="og:title" content="Ernesto's portfolio" />
        <meta
          property="og:image"
          content="https://media-exp1.licdn.com/dms/image/C4E16AQEU_ZS2lneIwQ/profile-displaybackgroundimage-shrink_350_1400/0/1622734911465?e=1628121600&v=beta&t=TM6pmhraURwTMCwBhGiD31sLFbnP9GDIVS9u6phG0zs"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Ernesto's Portfolio" />
      </Helmet>
      <App />
    </>
  );
};
